<template>
  <div class="dashboard-container">
    <div class="header-container flex flex-between">
      <div class="left-box"></div>
      <div class="right-box flex flex-start">
        <div class="clock">
          <div class="hour-hand"></div>
          <div class="minute-hand"></div>
        </div>
        <div class="date" id="fullScreenBtn1">{{ headDate }}</div>
      </div>
    </div>
    <div class="header-btn-container flex flex-between">
      <div class="left" @click="clickBackBtn">
        <span class="text">返回</span>
      </div>
      <div class="right flex flex-start">
        <div class="class-box-outer">
          <div class="class-box flex flex-between">
            <div class="text">{{ clazzName }}</div>
            <img src="@/assets/dashboard/class_down_icon.png" alt="">
          </div>
          <el-select v-model="clazzId" class="select-input" @change="classChange">
            <el-option v-for="(item,index) in clazzList.data" :value="item.value" :key="index"
                       :label="item.label"></el-option>
          </el-select>
        </div>
        <div class="select-box flex flex-between">
          <img src="@/assets/dashboard/date_box_icon.png" alt="" class="icon">
          <span class="start-date">{{ start_date_text }}</span>
          <span class="text">至</span>
          <span class="end-date">{{ end_date_text }}</span>
          <el-date-picker
              class="date_picker"
              v-model="dateRange"
              type="daterange"
              @change="dateChange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00','23:59:59']"
              value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="big-container flex flex-start">
      <div class="big-container-boxs flex flex-around flex-wrap">
        <div class="li-box">
          <div class="title-box">学生学习方式喜爱度</div>
          <div class="li-container" id="studyMethods-chart"></div>
        </div>
        <div class="li-box studyer-chart">
          <div class="title-box">学习者类型</div>
          <div class="li-container" id="studyer-chart"></div>
        </div>
        <div class="li-box">
          <div class="title-box">人数与完成率对比</div>
          <div class="li-container" id="completeRate-chart"></div>
        </div>
        <div class="li-box need-margin avgScore-chart">
          <div class="title-box">课后知识掌握测试</div>
          <div class="li-container">
            <div class="score-box flex flex-start">
              <div class="li flex flex-start">
                <div class="box school"></div>
                <div class="text">校平均分：{{ avgScore.school.totalScoreAvg }}</div>
              </div>
              <div class="li flex flex-start">
                <div class="box class"></div>
                <div class="text">班平均分：{{ avgScore.class.totalScoreAvg }}</div>
              </div>
            </div>
            <div id="avgScore-chart"></div>
          </div>
        </div>
        <div class="li-box need-margin sex-chart">
          <div class="title-box">人数与性别</div>
          <div class="li-container ">
            <div class="title">
              <span class="text">总人数：{{ studentPercent.total }}人</span>
            </div>
            <div class="chart-box flex flex-between">
              <div class="li male">
                <div class="num">男生：{{ studentPercent.male }}人</div>
                <div class="percent">{{ studentPercent.malePercent }}%</div>
              </div>
              <div class="li female">
                <div class="num">女生：{{ studentPercent.female }}人</div>
                <div class="percent">{{ studentPercent.femalePercent }}%</div>
              </div>
            </div>
            <div class="percent-box flex flex-start">
              <div class="li male" :style="'width:'+450*studentPercent.malePercent/100+'px'"></div>
              <div class="li female" :style="'width:'+450*studentPercent.femalePercent/100+'px'"></div>
            </div>
          </div>
        </div>
        <div class="li-box need-margin class-student-box">
          <div class="title-box">学生学习画像</div>
          <div class="li-container li-class flex flex-start flex-wrap" style="display: none" v-show="!clazzId">
            <div class="li" v-for="item in clazzList.data" @click="classChange(item.value)" v-if="item.value!==''">
              {{ item.label }}
            </div>
          </div>
          <div class="li-container li-student flex flex-start flex-wrap" v-show="clazzId">
            <div class="li" v-for="(item,index) in studentList"
                 @click="clickStudent(item,index)">{{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="company-box">技术服务：成都智云鸿道信息技术有限公司</div>
    <!--全屏按钮弹出框-->
    <div class="my-dialog-container" v-show="showFullDialog">
      <div class="mask"></div>
      <div class="dialog-box" style="background-color: transparent;width: 100%;top:50%">
        <div class="flex flex-center">
          <el-button id="fullScreenBtn" type="danger" size="">全屏查看</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {StudentModel} from "@/model/exp/StudentModel";
import {StatisticModel} from "@/model/exp/StatisticModel";
import {date_format, find_obj_from_arr_by_id} from "@/utils/common";
import elDragDialog from "@/directive/el-drag-dialog";
import {CommonModel} from "@/model/CommonModel";
import {ExpQuestionnaireModel} from "@/model/erp/ExpQuestionnaireModel";


// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import {BarChart, LineChart,PieChart,RadarChart} from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  DataZoomComponent
} from 'echarts/components';
// 标签自动布局、全局过渡动画等特性
import {LabelLayout, UniversalTransition} from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {CanvasRenderer} from 'echarts/renderers';

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
  DataZoomComponent,
  LineChart,
  PieChart,
  RadarChart
]);


export default {
  name: "v2Chart",
  directives: {
    elDragDialog
  },
  data() {
    return {
      date_format: date_format,
      headDate: "",
      headDateTimeId: "",
      start_time: null,
      end_time: null,
      start_date_text: "开始日期",
      end_date_text: "结束日期",
      clazzList: [],
      dateRange: [],
      schoolId: this.$route.query["schoolId"],
      clazzId: this.$route.query["clazzId"],
      clazzName: this.$route.query["clazzName"],
      experimentList: [],
      studentList: [],
      studentPercent: {
        male: 0,
        malePercent: 0.0,
        female: 0,
        feMalePercent: 0.0,
        total: 0,
      },
      studentAccountList: [],
      showFullDialog: false,

      // 课外知识平均分对象
      avgScore: {
        school: {
          got: false,
          totalScoreAvg: 0
        },
        class: {
          totalScoreAvg: 0
        },
      },

      // 学习方式数据
      studyMethods: {
        methods: ['阅读', '听讲', '看视频', '2D互动', '3D互动', 'VR互动', '模拟讲课', '角色扮演', '情景演绎', '小组辩论'],
        indexObject: {
          "阅读": 0, "听讲": 1, "看视频": 2, "2D互动": 3, "3D互动": 4, "VR互动": 5, "模拟讲课": 6, "角色扮演": 7, "情景演绎": 8, "小组辩论": 9
        },
      },
      // 学习者类型
      studyer: {
        indexObject: {"视觉型": 0, "听觉型": 1, "读写型": 2, "动态型": 3, "混合型": 4}
      }
    }
  },
  async mounted() {
    if (this.$route.query["clazzId"] === "null") {
      this.clazzId = ""
      this.clazzName = "全部教学班"
    }
    if (this.$route.query["start_time"]) {
      if (this.$route.query["start_time"] === "null") {
        this.start_time = null
        this.end_time = null
      } else {
        this.start_time = this.$route.query["start_time"];
        this.end_time = this.$route.query["end_time"];
        this.start_date_text = this.start_time.slice(0, 10)
        this.end_date_text = this.end_time.slice(0, 10)
      }
    }
    // 顶部每秒日期更新
    this.headDateTimeId = setInterval(() => {
      this.formatHeadDate()
    }, 1000)
    // 如果不是全屏状态就弹出全屏按钮
    if (!document.fullscreenElement) {
      this.showFullDialog = true
    }
    let $this = this;
    document.getElementById("fullScreenBtn").onclick = function () {
      if (document.documentElement.RequestFullScreen) {
        document.documentElement.RequestFullScreen();
      }
      //兼容火狐
      if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      }
      //兼容谷歌等可以webkitRequestFullScreen也可以webkitRequestFullscreen
      if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen();
      }
      //兼容IE,只能写msRequestFullscreen
      if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
      setTimeout(() => {
        $this.showFullDialog = false
      }, 1000)
    }
    window.onresize = function () {
      if ($this.showFullDialog === false) {
        $this.showFullDialog = true
      }
    }
    // 监听班级选择框点击
    window.$(".class-box").click(() => {
      window.$(".select-input").click()
    })
    // 监听日期选择框点击
    window.$(".select-box .start-date,.select-box .end-date").click(() => {
      window.$(".date_picker").click()
    })
    // 获取教学班级列表
    await this.getClazzList()
    this.getData()
  },
  beforeDestroy() {
    //  组件销毁时清除定时器
    clearInterval(this.headDateTimeId)
  },
  methods: {
    // 获取数据
    async getData() {
      if (this.clazzId === "") {
        this.clazzId = null
      }
      if (this.clazzId) {// 如果选择的是某个班级
        await this.getStudentList() // 就获取该班级学生列表
        await this.getDashboardClazzExperiments() // 就获取该班级的所有实验列表
      } else { // 如果是全部班级
        this.getSchoolStudentList()
        await this.getSchoolExperimentList() // 就获取全校所有实验列表
      }
      this.getExpQuestionnaireList()
      this.getExperimentData()
      this.getAvgScore()
    },
    // 班级选择变动
    async classChange(v) {
      this.studentList = []
      this.studentPercent = {
        male: 0,
        malePercent: 0.0,
        female: 0,
        feMalePercent: 0.0,
        total: 0,
      };
      this.clazzId = v
      this.clazzName = this.clazzList.object[v]
      this.getData()
    },
    // 获取所有教学班列表
    async getClazzList() {
      let clazzList = await StatisticModel.dashboard_index_all_clazz_list(this.schoolId)
      if (clazzList) {
        let filterList = CommonModel.generateListFilterOptions("name", "id", clazzList, true);
        let clazzData=[]
        for(let i=0;i<filterList[0].length;i++){
          if(filterList[0][i].label.indexOf('teacher')===-1){
            clazzData.push(filterList[0][i])
          }
        }
        let clazzListTemp = {
          data: clazzData,
          object: filterList[1]
        }
        this.clazzList = clazzListTemp
      }
    },
    // 日期选择变动
    dateChange(v) {
      this.$set(this.avgScore.school, "got", false) // todo 决定-变更日期是是否重新获取校级知识分数据
      this.start_time = v[0]
      this.end_time = v[1]
      this.start_date_text = v[0].slice(0, 10)
      this.end_date_text = v[1].slice(0, 10)
      this.getData()
    },
    // 点击返回按钮
    clickBackBtn() {
      this.$router.push("/dashboard/v2/index?schoolId="+this.schoolId)
    },
    // 点击某个学生
    async clickStudent(student, index) {
      // this.studentInfo = student
      console.log(student)
      this.$router.push("/dashboard/v2/class?clazzId=" + this.clazzId + "&schoolId=" +
          this.schoolId + "&clazzName=" + this.clazzName + "&studentIndex=" + index + "&start_time=" + this.start_time + "&end_time=" + this.end_time)
    },
    // 获取学生列表
    async getStudentList() {
      let query = {
        clazzid: this.clazzId
      };
      [this.studentList,] = await StudentModel.getList(1, 1000, query)
      this.getStudentSexInfo(this.studentList)
      // 遍历学生列表获取所有account列表
      this.studentAccountList = []
      let total = this.studentList.length;
      for (let i = 0; i < total; i++) {
        this.studentAccountList.push(this.studentList[i].account)
      }
    },
    // 获取全校学生列表
    async getSchoolStudentList() {
      for (let i = 0; i < this.clazzList.data.length; i++) {
        let clazzId = this.clazzList.data[i]["value"]
        if (clazzId && !this.clazzId) {
          let studentList = []
          let query = {
            clazzid: clazzId
          };
          [studentList,] = await StudentModel.getList(1, 1000, query)
          if(!this.clazzId){
            this.getStudentSexInfo(studentList)
          }
        }
      }
    },
    // 统计学生性别信息
    getStudentSexInfo(studentList) {
      let maleNum = 0;
      let femaleNum = 0;
      let total = studentList.length;
      for (let i = 0; i < total; i++) {
        if (studentList[i].sex === "男") {
          maleNum++
        } else {
          femaleNum++
        }
      }
      this.$set(this.studentPercent, "male", this.studentPercent.male + maleNum)
      this.$set(this.studentPercent, "female", this.studentPercent.female + femaleNum)
      this.$set(this.studentPercent, "total", this.studentPercent.total + total)
      this.$set(this.studentPercent, "malePercent", (this.studentPercent.male / this.studentPercent.total * 100).toFixed(0))
      this.$set(this.studentPercent, "femalePercent", (this.studentPercent.female / this.studentPercent.total * 100).toFixed(0))
    },
    // 获取调查表列表 todo 后端获取
    async getExpQuestionnaireList() {
      let $this = this
      // 获取规定时间内的每个学生的最新调查表
      let query = {
        "asOld": false,
        "schoolId": this.schoolId,
      } // todo createTime
      if (this.clazzId) {// 如果选择的某个班级，就获取该班级的调查表
        query = Object.assign(query, {
          "account": {
            "$in": this.studentAccountList
          }
        })
      }
      if (this.start_time) {// 如果选择了日期
        query["$and"] = [
          {
            createTime: {
              '$gte': new Date(this.start_time).getTime()
            }
          },
          {
            createTime: {
              '$lte': new Date(this.end_time).getTime()
            }
          }
        ]
      }

      let allList = await ExpQuestionnaireModel.getList(query)
      // 整理学生喜爱的学习方式数据
      let studyMethodsData = [ // todo itemStyle,formatter应该不用重复设置
        {
          value: 0.0,
          num: 0,
          itemStyle: {
            color: '#faef6b'
          },
          label: {
            show: true,
            formatter: (c) => {
              let li = c["data"]
              return `${li["num"]}` + "人"
            }
          },
        },
        {
          value: 0.0,
          num: 0,
          itemStyle: {
            color: '#dab44a'
          },
          label: {
            show: true,
            formatter: (c) => {
              let li = c["data"]
              return `${li["num"]}` + "人"
            }
          },
        },
        {
          value: 0.0,
          num: 0,
          itemStyle: {
            color: '#d59043'
          },
          label: {
            show: true,
            formatter: (c) => {
              let li = c["data"]
              return `${li["num"]}` + "人"
            }
          },
        },
        {
          value: 0.0,
          num: 0,
          itemStyle: {
            color: '#e97e57'
          },
          label: {
            show: true,
            formatter: (c) => {
              let li = c["data"]
              return `${li["num"]}` + "人"
            }
          },
        },
        {
          value: 0.0,
          num: 0,
          itemStyle: {
            color: '#406af4'
          },
          label: {
            show: true,
            formatter: (c) => {
              let li = c["data"]
              return `${li["num"]}` + "人"
            }
          },
        },
        {
          value: 0.0,
          num: 0,
          itemStyle: {
            color: '#3d43f4'
          },
          label: {
            show: true,
            formatter: (c) => {
              let li = c["data"]
              return `${li["num"]}` + "人"
            }
          },
        },
        {
          value: 0.0,
          num: 0,
          itemStyle: {
            color: '#5c4cd7'
          },
          label: {
            show: true,
            formatter: (c) => {
              let li = c["data"]
              return `${li["num"]}` + "人"
            }
          },
        },
        {
          value: 0.0,
          num: 0,
          itemStyle: {
            color: '#8739f5'
          },
          label: {
            show: true,
            formatter: (c) => {
              let li = c["data"]
              return `${li["num"]}` + "人"
            }
          },
        },
        {
          value: 0.0,
          num: 0,
          itemStyle: {
            color: '#5ac4f8'
          },
          label: {
            show: true,
            formatter: (c) => {
              let li = c["data"]
              return `${li["num"]}` + "人"
            }
          },
        },
        {
          value: 0.0,
          num: 0,
          itemStyle: {
            color: '#479f9c'
          },
          label: {
            show: true,
            formatter: (c) => {
              let li = c["data"]
              return `${li["num"]}` + "人"
            }
          },
        },
      ]
      // 整理学习者类型数据
      let studyerData = [
        {
          value: 0, percent: 0, name: '视觉型',
          label: {
            show: true,
            formatter: (c) => {
              let li = c["data"]
              return "视觉型( " + `${li["percent"]}` + "% " + `${li["value"]}` + "人 )"
            }
          },
        },
        {
          value: 0, percent: 0, name: '听觉型',
          label: {
            show: true,
            formatter: (c) => {
              let li = c["data"]
              return "听觉型( " + `${li["percent"]}` + "% " + `${li["value"]}` + "人 )"
            }
          },
        },
        {
          value: 0, percent: 0, name: '读写型',
          label: {
            show: true,
            formatter: (c) => {
              let li = c["data"]
              return "读写型( " + `${li["percent"]}` + "% " + `${li["value"]}` + "人 )"
            }
          },
        },
        {
          value: 0, percent: 0, name: '动态型',
          label: {
            show: true,
            formatter: (c) => {
              let li = c["data"]
              return "动态型( " + `${li["percent"]}` + "% " + `${li["value"]}` + "人 )"
            }
          },
        },
        {
          value: 0, percent: 0, name: '混合型',
          label: {
            show: true,
            formatter: (c) => {
              let li = c["data"]
              return "混合型( " + `${li["percent"]}` + "% " + `${li["value"]}` + "人 )"
            }
          },
        },
      ]
      let allLength = allList.length
      for (let i = 0; i < allLength; i++) {
        let questionnaire = allList[i]
        // 学习方式
        questionnaire.info.studyMethods.forEach(li => {
          let index = $this.studyMethods.indexObject[li]
          studyMethodsData[index].num++
          studyMethodsData[index].value = studyMethodsData[index].num / allLength * 100
        })
        // 学习者类型
        let studyerIndex = this.studyer.indexObject[questionnaire.info.studyer]
        studyerData[studyerIndex].value++
        studyerData[studyerIndex].percent = studyerData[studyerIndex].value / allLength * 100
        // todo 矫正学生性别，遍历学生列表，对应学号的修改为调查表上的性别
        //let [si,] = find_obj_from_arr_by_id("account", questionnaire.account, this.studentList)
        //this.$set(this.studentList[si], "sex", questionnaire.sex)
      }
      this.drawStudyMethodsChart(studyMethodsData)
      this.drawStudyerChart(studyerData)
    },
    // 学习方式喜爱度图表
    drawStudyMethodsChart(data) {
      let chartDom = document.getElementById('studyMethods-chart');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        tooltip: {
          enterable: true,
          trigger: 'axis',
          formatter: (c) => {
            let li = c[0]["data"]
            return "占比：" + `${li["value"]}` + "% 人数：" + `${li["num"]}` + "人"
          }
        },
        xAxis: {
          type: 'category',
          data: this.studyMethods.methods,
          axisLabel: {
            fontSize: 14,
            color: '#fff',
            interval: 0,
            rotate: 0,
            lineHeight: 15,
            formatter: function (value) {
              return value.split('').join('\n')
            }
          }
        },
        yAxis: {axisLabel: {formatter: '{value}%'}},
        series: [
          {
            data: data,
            type: 'bar',
          }
        ],
      };
      option && myChart.setOption(option);
    },
    // 学习者类型图表
    drawStudyerChart(data) {
      let chartDom = document.getElementById('studyer-chart');
      let myChart = echarts.init(chartDom);
      let option = {
        tooltip: {
          enterable: true,
          formatter: (c) => {
            let li = c["data"]
            return "占比：" + `${li["percent"]}` + "% 人数：" + `${li["value"]}` + "人"
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: [30, 130],
            center: ['50%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 8
            },
            data
          }
        ]
      }
      option && myChart.setOption(option);
    },
    // 格式化顶部日期
    formatHeadDate() {
      let date = new Date();
      const weekdays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const weekday = weekdays[date.getDay()];
      const hour = date.getHours();
      const minute = date.getMinutes();
      // 使用模板字符串拼接出格式化后的日期时间字符串
      this.headDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day} ${weekday} ${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`;
    },
    // 获取本班级安排的所有实验列表
    async getDashboardClazzExperiments() {
      this.experimentList = await StatisticModel.getDashboardClazzExperiments(this.clazzId)
    },
    // 获取本校所有实验列表
    async getSchoolExperimentList() {
      let experimentList = await StatisticModel.getOneSchoolExperimentInfoList(this.schoolId)
      if (experimentList) {
        this.experimentList = experimentList
      }
    },
    // 获取某个实验的数据
    async getExperimentData() {
      let experimentList = []
      let scheduleNumList = []
      let completeRateList = []
      for (let i = 0; i < this.experimentList.length; i++) {
        let experiment = this.experimentList[i]
        let experimentId = experiment.id
        let result = await StatisticModel.dashboard_index_1(this.schoolId, this.clazzId, this.start_time, this.end_time, experimentId)
        if (result) {
          if (result.scheduleNum === 0) {
            result.completeRate = 0;
          } else {
            result.completeRate = (result.completeNum / result.scheduleNum).toFixed(2) * 100;
          }
          // 加入数据表
          experimentList.push(experiment.name)
          scheduleNumList.push(result.scheduleNum)
          completeRateList.push(result.completeRate)
        }
      }
      this.drawStudentNumAndCompleteRateChart(experimentList, scheduleNumList, completeRateList)
    },
    // 绘制人数与完成率对比图
    drawStudentNumAndCompleteRateChart(experimentList, scheduleNumList, completeRateList) {
      let chartDom = document.getElementById('completeRate-chart');
      let myChart = echarts.init(chartDom);
      let option = {
        dataZoom: [
          {
            type: "slider", //隐藏或显示（true）组件
            show: true,
            backgroundColor: "rgb(19, 63, 100)", // 组件的背景颜色。
            fillerColor: "rgb(16, 171, 198)", // 选中范围的填充颜色。
            borderColor: "rgb(19, 63, 100)", // 边框颜色
            showDetail: true, //是否显示detail，即拖拽时候显示详细数值信息
            startValue: 0,
            endValue: 5,
            filterMode: "empty",
            width: "100%", //滚动条高度
            height: 15, //滚动条显示位置
            left: "center",
            zoomLoxk: true, // 是否锁定选择区域（或叫做数据窗口）的大小
            handleSize: 0, //控制手柄的尺寸
            bottom: 3, // dataZoom-slider组件离容器下侧的距离
          },
          {
            //没有下面这块的话，只能拖动滚动条，鼠标滚轮在区域内不能控制外部滚动条
            type: "inside",
            zoomOnMouseWheel: false, //滚轮是否触发缩放
            moveOnMouseMove: true, //鼠标滚轮触发滚动
            moveOnMouseWheel: true,
          },
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#fff'
            }
          }
        },
        legend: {
          data: ['安排人数', '完成率'],
          textStyle: {
            color: 'rgb(255,255,255,0.9)'
          }
        },
        xAxis: [
          {
            type: 'category',
            data: experimentList,

          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '安排人数',
            axisLabel: {
              formatter: '{value} 人',
              fontSize: 10,
              color: '#fff',
              interval: 0,
              rotate: 0,
              lineHeight: 15,
            },
            itemStyle: {
              color: '#faef6b'
            },
          },
          {
            type: 'value',
            name: '完成率',
            axisLabel: {
              formatter: '{value} %',
              fontSize: 10,
              color: '#fff',
              interval: 0,
              rotate: 0,
              lineHeight: 15,
            }
          }
        ],
        series: [
          {
            name: '安排人数',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 人';
              }
            },
            data: scheduleNumList,
            itemStyle: {
              normal: {
                color: '#51589c'
              }
            }
          },
          {
            name: '完成率',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' %';
              }
            },
            data: completeRateList,
            itemStyle: {
              normal: {
                color: '#72bfd1'
              }
            }
          }
        ]
      };
      option && myChart.setOption(option);
    },
    // 获取课后知识平均分,绘制图表
    async getAvgScore() {
      if (!this.avgScore.school.got) {
        let avgSchool = {}
        if (this.start_time) {
          avgSchool = await ExpQuestionnaireModel.getAvgScoreOfSchool(this.schoolId, null, null, new Date(this.start_time).getTime(), new Date(this.end_time).getTime())
        } else {
          avgSchool = await ExpQuestionnaireModel.getAvgScoreOfSchool(this.schoolId, null, null, null, null)
        }
        this.avgScore.school = avgSchool
        avgSchool.got = true
        let s = avgSchool["scoreObjectAvg"]
        this.avgScore.school.avgArr = [s["1"], s["2"], s["3"], s["4"], s["5"], s["6"]]
      }
      let avgClass = {}
      if (this.start_time) {
        avgClass = await ExpQuestionnaireModel.getAvgScoreOfClass(this.schoolId, this.studentAccountList, null, new Date(this.start_time).getTime(), new Date(this.end_time).getTime())
      } else {
        avgClass = await ExpQuestionnaireModel.getAvgScoreOfClass(this.schoolId, this.studentAccountList, null, null, null)
      }
      this.avgScore.class = avgClass
      let c = avgClass["scoreObjectAvg"]
      let avgArrClass = [c["1"], c["2"], c["3"], c["4"], c["5"], c["6"]]
      let chartDom = document.getElementById("avgScore-chart");
      let myChart = echarts.init(chartDom);
      let option = {
        // legend: {
        //   data: ['校平均分', '班平均分'],
        //   textStyle: {
        //     color: 'rgb(255,255,255,0.9)'
        //   }
        // },
        radar: {
          shape: 'circle',
          indicator: [
            {name: '人物知识'},
            {name: '历史知识'},
            {name: '情感'},
            {name: '价值观'},
            {name: '精神谱系'},
            {name: '思政学科知识'}
          ]
        },
        series: [
          {
            type: 'radar',
            data: [
              {
                areaStyle: {
                  color: "#22527f"
                },
                label: {
                  show: true,
                  formatter: function (params) {
                    return params.value;
                  }
                },
                value: this.avgScore.school.avgArr,
                name: '校平均分'
              },
              {
                areaStyle: {
                  color: "#54b8db"
                },
                label: {
                  show: true,
                  formatter: function (params) {
                    return params.value;
                  }
                },
                value: avgArrClass,
                name: '班平均分'
              }
            ]
          }
        ]
      };
      option && myChart.setOption(option);
    }
  }
}
</script>

<style scoped lang="less">
@import '../../style/app.less';

div.dashboard-container {
  background-image: url('../../assets/dashboard/background.png');
  //background-position: center 85%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  width: 100%;
  font-family: MicrosoftYaHei;
  color: #2EB8FF;
  position: relative;
}

// 顶部
div.header-container {
  width: 100%;
  height: 113px;
  background-image: url('../../assets/dashboard/head_title.png');
  background-position: center;

  div.right-box {
    margin-bottom: 40px;
    margin-right: 20px;

    .clock {
      width: 25px;
      height: 25px;
      border: 2px solid #2EB8FF;
      border-radius: 50%;
      position: relative;
      margin-right: 10px;
    }

    .hour-hand {
      width: 2px;
      height: 6px;
      background-color: #2EB8FF;
      position: absolute;
      top: 25%;
      left: 50%;
      transform-origin: bottom center;
      transform: translateX(-50%) rotate(0deg);
    }

    .minute-hand {
      width: 2px;
      height: 7px;
      background-color: #2EB8FF;
      position: absolute;
      top: 19%;
      left: 50%;
      transform-origin: bottom center;
      transform: translateX(-50%) rotate(140deg);
    }

    .date {
      font-size: 18px;
      color: #2EB8FF;
    }
  }
}

// 顶部按钮框
div.header-btn-container {
  margin-top: -40px;

  div.left {
    margin-left: 20px;
    width: 187px;
    height: 59px;
    position: relative;
    cursor: pointer;
    background-image: url("../../assets/dashboard/class/btn_back.png");
    background-position: center;

    &:hover {
      background-image: url("../../assets/dashboard/class/btn_back_hover.png");
    }


    span.text {
      width: 100px;
      position: absolute;
      left: 100px;
      top: 18px;
      z-index: 1;
      font-size: 25px;
      color: #fff;
    }
  }

  div.right {
    margin-right: 20px;

    div.class-box-outer {
      position: relative;
      width: 400px;
      height: 42px;
      margin-right: 10px;
      cursor: pointer;

      div.class-box {
        position: absolute;
        width: 400px;
        height: 42px;

        .text {
          width: 400px;
          position: absolute;
          height: 42px;
          line-height: 42px;
          font-weight: bolder;
          padding: 0px 10px;
          font-size: 17px;
          background-color: #091b3e;
          border: 2px solid #15254c;
        }

        img {
          width: 24px;
          height: 15px;
          position: absolute;
          right: 10px;
          bottom: 13px;
        }
      }

      .select-input {
        width: 400px;
        position: absolute;
        top: 0px;
        height: 42px;
        z-index: -1;
      }
    }

    .select-box {
      position: relative;
      background-image: url('../../assets/dashboard/date_box_select_background.png');
      width: 340px;
      padding: 0px 0px 0px 20px;
      height: 40px;
      line-height: 40px;

      img.icon {
        width: 26px;
        height: 25px;
      }

      span.start-date, span.end-date {
        cursor: pointer;
        font-size: 16px;
        color: #0ac8ff;
        width: 100px;
        height: 40px;
        text-align: center;
      }

      span.end-date {
        margin-right: 15px;
      }

      span.text {
        font-size: 20px;
        color: #f2f6ff;
        width: 30px;
        height: 40px;
        text-align: center;
      }

      .date_picker {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 340px;
        z-index: -1;
      }
    }
  }
}

// 大内容框
div.big-container {
  width: 1872px;
  height: 907px;
  margin: 0 auto;
  position: relative;
  background-image: url('../../assets/dashboard/v2/class_big_container_background.png');
  background-position: center;

  .big-container-boxs {
    margin: 30px;
    padding: 15px 0px;
    background-color: rgba(12, 39, 77, 0.8);
  }

  div.li-box {
    width: 572px;
    height: 399px;
    background-image: url('../../assets/dashboard/v2/class_bottom_right_background.png');
    background-position: center;
    position: relative;

    &.need-margin {
      margin-top: 30px;
    }

    div.title-box {
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      color: #63d8e2;
      font-size: 18px;
    }

    .li-container {
      padding: 10px;
      margin: 20px;
      margin-left: 25px;
      height: 370px;
    }
  }

  div.studyer-chart {
    .li-container {
      padding: 0px;
    }
  }

  div.li-box.class-student-box {

    .li-container {
      padding: 10px;
      margin: 20px;
      margin-left: 25px;
      height: 370px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        background-color: #32747c;
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #75fbfd;
      }

      .li {
        background-color: #215169;
        width: 230px;
        height: 40px;
        line-height: 40px;
        color: #58bdf3;
        font-size: 17px;
        text-align: center;
        border: 1px solid #215169;
        cursor: pointer;
        margin-top: 12px;
        margin-left: 15px;
        overflow: hidden;

        &:hover {
          background-color: #2f6d83;
          color: #6eedf5;
          border: 1px solid #6decf3;
        }
      }
    }

    .li-container.li-student {
      margin: 5px;
      margin-top: 20px;

      .li {
        width: 119px;
      }
    }
  }

  div.li-box.sex-chart {
    div.title {
      margin-top: 20px;
      text-align: center;
      line-height: 34px;
      width: 175px;
      height: 34px;
      background-image: url('../../assets/dashboard/v2/chart_sex_title_background.png');

      .text {
        color: #fff;
        font-size: 17px;
      }
    }

    div.chart-box {
      margin-top: 40px;
      padding: 0px 50px;

      div.li {
        width: 138px;
        height: 139px;
        position: relative;
        color: #fff;
        text-align: center;

        &.male {
          background-image: url('../../assets/dashboard/v2/chart_male.png');

          .num {
            color: #7dcfd6;
          }
        }

        &.female {
          background-image: url('../../assets/dashboard/v2/chart_female.png');

          .num {
            color: #c97cd7;
          }
        }

        .num {
          width: 100px;
          position: absolute;
          bottom: 15px;
          font-size: 15px;
          left: 50%;
          transform: translateX(-50%);
        }

        .percent {
          width: 100px;
          position: absolute;
          bottom: -30px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 20px;
        }
      }
    }

    div.percent-box {
      width: 450px;
      margin: 0 auto;
      margin-top: 80px;

      .li {
        width: 450px;
        height: 15px;

        &.male {
          background: linear-gradient(to right, #87dbd1 0%, #4b97f6 100%);
        }

        &.female {
          background: linear-gradient(to right, #965cc3 0%, #e88fdf 100%);
        }
      }
    }
  }

  div.li-box.avgScore-chart {
    .li-container {
      padding: 0px;
      position: relative;

      #avgScore-chart {
        height: 370px;
        padding-top: 30px;
      }
    }

    .score-box {
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      top: 15px;

      .li {
        margin-right: 20px;

        .box {
          width: 15px;
          height: 15px;
          margin-right: 5px;

          &.school {
            background-color: #22527f;
          }

          &.class {
            background-color: #54b8db;
          }
        }

        .text {
          font-size: 13px;
          color: #fff;
        }
      }
    }
  }
}

div.company-box {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  width: 350px;
  height: 17px;
  font-size: 18px;
  font-family: SimHei;
  color: #50A2B0;
  line-height: 28px;
  background: radial-gradient(NaNdeg, #209CFF 0%, #68E0CF 0%);
  -webkit-background-clip: text;
}
</style>
