import { render, staticRenderFns } from "./v2Chart.vue?vue&type=template&id=1add8dc7&scoped=true&"
import script from "./v2Chart.vue?vue&type=script&lang=js&"
export * from "./v2Chart.vue?vue&type=script&lang=js&"
import style0 from "./v2Chart.vue?vue&type=style&index=0&id=1add8dc7&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1add8dc7",
  null
  
)

export default component.exports